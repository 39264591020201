<template>
    <div>
        hello
        {{userList}}
        </div>
</template>
<script>
    import { userMixin } from '../../mixins/userMixin'
    export default {
        name: "UserList",
        mixins: [userMixin],
        data: () => ({
            userList: []
        }),
        async mounted(){
            const response = await this.getUserList(2, 10)
            console.log(response.data.userListForAdmin.users)
            this.userList = response.data.userListForAdmin.users
        }
    }
</script>